<template>
    <div class="order-input-container">
        <div class="order-input-container-top">
            <van-nav-bar title="新增库存" fixed/>
        </div>
        <div class="order-input-container-form">
            <van-cell-group>
                <van-field name="sku" v-model="form.sku" type="text" :readonly="form.id>0" clearable label="商品sku" placeholder="请输入商品sku" maxlength="100"></van-field>
                <van-field name="name" v-show="form.id>0" v-model="form.name" type="text" readonly label="商品名称"></van-field>
                <van-field name="spec" v-show="form.id>0" v-model="form.spec" type="text" readonly label="商品规格"></van-field>
                <van-field name="city_id" label="商品城市">
                    <template #input>
                        <van-checkbox-group v-model="form.city_ids" direction="horizontal">
                            <van-checkbox v-for="(item, index) in cityList" :key="index" :name="item.index + ''" shape="square">{{item.text}}</van-checkbox>
                        </van-checkbox-group>
                    </template>
                </van-field>
                <van-field name="total" v-show="form.id===0" label="商品库存">
                    <template #input>
                        <van-stepper v-model="form.total" min="1" />
                    </template>
                </van-field>
                <van-field name="status" label="使用状态">
                    <template #input>
                        <van-switch v-model="form.status" :active-value="1" :inactive-value="0" size="20" />
                    </template>
                </van-field>
                <van-field name="forewarn" label="预警状态">
                    <template #input>
                        <van-switch v-model="form.forewarn" :active-value="1" :inactive-value="0" size="20" />
                    </template>
                </van-field>
                <van-field name="forewarn_num" v-show="form.forewarn===1" label="预警库存">
                    <template #input>
                        <van-stepper v-model="form.forewarn_num" min="1" />
                    </template>
                </van-field>
            </van-cell-group>
        </div>
        <div class="order-input-container-btn">
            <van-button type="info" icon="passed" size="large" @click="onSubmit" text="提交"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'stock-input',
        components: {
        },
        props: ['data'],
        data() {
            return {
                cityList: [],
                form: {
                    city_id: '',
                    city_ids: [],
                    city_name: '',
                    complete: 0,
                    forewarn: 0,
                    forewarn_num: 0,
                    id: 0,
                    sku: '',
                    name: '',
                    spec: '',
                    status: 0,
                    stock: 0,
                    total: 0,
                }
            }
        },
        mounted() {
            this.form = this.data;
            this.loadCity();
        },
        methods: {
            loadCity() {
                let that = this;
                if (that.cityList.length === 0) {
                    that.$api.region.loadCity(0).then(response => {
                        let res = response.data;
                        if (res.code === 1) {
                            that.cityList = res.data;
                        }
                    });
                }
            },
            // 提交订单
            onSubmit() {
                let that = this;
                if(this.form.id > 0){
                    that.$api.stock.edit(this.form).then(response => {
                        let res = response.data;
                        if (res.code === 1) {
                            that.$emit('onClose')
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                } else {
                    that.$api.stock.add(this.form).then(response => {
                        let res = response.data;
                        if (res.code === 1) {
                            that.$emit('onClose')
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                }
            }
        }
    }
</script>

<style scoped>
    .order-input-container {
        background-color: #f5f5f5;
        height: 100%;
        width: 100%;
    }

    .order-input-container-form {
        position: fixed;
        top: 46px;
        bottom: 50px;
        overflow-y: scroll;
        left: 0;
        right: 0;
    }

    .order-input-container-btn {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50px;
    }

    .van-cell__value {
        flex: 3
    }

    .order-address {
        padding: 0 20px 10px;
    }

    .order-address-consignee {
        margin-bottom: 4px;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
    }

    .order-address-detail {
        color: #7d7e80;
        font-size: 12px;
        line-height: 16px;
    }

    .order-address-detail .van-tag {
        margin-right: 5px;
    }

    .order-address-detail .van-tag:last-child {
        margin-right: 0;
    }

    .goods-list {

    }

    .goods-list .goods-item {
        display: flex;
        padding: 10px 16px;
        color: #323233;
        font-size: 14px;
        line-height: 24px;

    }

    .goods-list .goods-item.border {
        border-bottom: 1px solid #ebedf0;
    }

    .goods-list .goods-item .goods-name {
        display: flex;
        flex: 2;
        position: relative;
        overflow: hidden;
        text-align: left;
        vertical-align: middle;
    }

    .goods-list .goods-item .goods-spec {
        position: relative;
        overflow: hidden;
        color: #969799;
        float: right;
    }

    .send-time-popup {
        position: relative;
    }

    .send-time-popup-title {
        position: relative;
        width: 100%;
        height: 46px;
        background: #fff;
    }

    .send-time-popup-title .van-nav-bar {
        position: fixed;
        width: 100%;
    }

    .send-time-list {
        padding: 4px;
    }

    .send-time-list .send-time-item {
        text-align: center;
        border: 1px solid #eee;
        margin: 4px;
        border-radius: 5px;
    }

    .send-time-list .send-time-item.active {
        border: 1px solid #1989fa;
        background-color: #1989fa40;
    }

    .send-time-list .send-time-item.disabled {
        background-color: #f5f5f5;
        color: #e0e0e0;
    }

</style>
